@import 'styles/partials/variables';
@import 'styles/partials/breakpoints';

.splash__container {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to left bottom,
    #ff5e5e,
    #ff6452,
    #ff6c45,
    #ff7537,
    #ff7e27,
    #ff824c,
    #ff8969,
    #ff9283,
    #ffaaba,
    #fdc8e3,
    #f6e6f9,
    #ffffff
  );

  svg {
    position: absolute;
    display: block;
    opacity: 1;
    width: 220px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);

    @include respond-to(md) {
      width: 50%;
      max-width: 300px;
    }

    &.animate {
      //animation: bounceIn 1s linear 0s infinite alternate;
      path {
        fill: transparent;
        stroke: white;
        stroke-width: 1px;
        stroke-linejoin: round;
        stroke-linecap: round;
        animation-name: fillStroke;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-duration: 16s;
        animation-timing-function: ease-in-out;
      }
    }

    &.cloud {
      path {
        stroke-dasharray: 93;
        stroke-dashoffset: 93;
        animation-delay: 0s;
      }
    }

    &.snow {
      path {
        stroke-dasharray: 226;
        stroke-dashoffset: 226;
        animation-delay: 4s;
      }
    }

    &.sun {
      path {
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation-delay: 8s;
      }
    }
    &.rainy {
      path {
        stroke-dasharray: 142;
        stroke-dashoffset: 142;
        animation-delay: 12s;
      }
    }
  }
}

@keyframes fillStroke {
  24% {
    stroke-dashoffset: 1;
    fill: transparent;
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
