@import 'styles/partials/variables';

a.viewer-link {
  color: $primary-color-dk;
  text-decoration: none;
  transition: all .2s ease-in;

  &:hover {
    transition: all .2s ease-out;
    box-shadow:  0 -1px 0 0 $primary-color-dk inset;
  }
}
