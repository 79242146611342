@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,700&display=swap');

// Themes
@import './assets/styles/themes.scss';
//


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
  touch-action: manipulation;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
  }
  ::-webkit-scrollbar-track {
    background-color: var(--secondary-color-lg);
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.recharts-tooltip-wrapper {
  max-width: calc(100vw - 20%);
  .recharts-default-tooltip {
    white-space: normal !important;
  }
}

* {
  box-sizing: border-box;
}