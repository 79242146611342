@import 'styles/partials/shadows', 'styles/partials/variables',
  'styles/partials/breakpoints';

@mixin responsive {
  width: 100vw;

  bottom: 0px;
  left: 0px;

  flex-direction: column;

  border-radius: 4px 4px 0px 0px;

  .button-row {
    width: 100%;
    justify-content: flex-end;
  }
}

.cookies-banner {
  position: fixed;

  width: 90vw;

  bottom: 15px;
  left: 5vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;

  border-radius: 4px;

  padding: 2em;
  margin: 0 auto;

  @include shadow-16dp();

  z-index: 10001;

  transition: transform 150ms ease-in-out;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .cookies-text {
      color: $primary-color;
      padding-right: 2em;
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;

    button {
      border: 1px solid $primary-color;
      border-radius: 4px;
      box-shadow: none;

      padding: 0.5em 1em;
      cursor: pointer;

      &:hover {
        background-color: $primary-color;
      }

      &:first-child {
        margin-right: 1em;
      }
    }
  }

  @include respond-to(sm) {
    @include responsive();
  }
}

.cookies-banner-transition-enter {
  transform: translateY(100px);
  @include respond-to(sm) {
    transform: translateY(300px);
  }
}
.cookies-banner-transition-enter-active {
  transform: translateY(0px);
}
.cookies-banner-transition-exit {
  transform: translateY(100px);
  @include respond-to(sm) {
    transform: translateY(300px);
  }
}
.cookies-banner-transition-exit-exit {
  transform: translateY(0px);
}
