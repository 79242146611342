
:root {
  --primary-color: #a6a6a6;
  --primary-color-dk: #FF7800;
  --primary-color-lg-40: #48CAE4;
  --primary-color-lg-60: #90E0EF;
  --primary-color-lg-80: #ADE8F4;

  --secondary-color: #a6a6a6;
  --secondary-color-dk: #3e3e3e;
  --secondary-color-lg: #F0F0F0;
}
