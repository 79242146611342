// Colors
$primary-color: var(--primary-color);
$primary-color-dk: var(--primary-color-dk);
$primary-color-lg: var(--primary-color-lg-40);
$primary-color-lg-40: var(--primary-color-lg-40);
$primary-color-lg-60: var(--primary-color-lg-60);
$primary-color-lg-80: var(--primary-color-lg-80);


$secondary-color: var(--secondary-color);
$secondary-color-dk: var(--secondary-color-dk);
$secondary-color-lg: var(--secondary-color-lg);
