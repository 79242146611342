@import 'styles/partials/variables';

.App {
  width: 100%;
  height: 100%;
  max-height: 100vh;

  text-align: start;


  h3 {
    font-weight: 300;
    margin: 0.5em 0;
    color: $secondary-color-dk;
  }

  .predictia-viewer__app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 0px 1em;
    overflow: hidden;

    margin-bottom: 0.5em;

    background-color: white;
    border-radius: 5px;
  }
}
